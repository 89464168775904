import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import PlayButton from '../static/play--button.svg';

export const query = graphql`
  query AvivaMwPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      media {
        video {
          asset {
            url
          }
        }
      }
    }
    static: sanityAviva(slug: { current: { eq: "aviva-myworkplace" } }) {
      bannerMedia {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            url
          }
        }
        alt
      }
    }
  }
`;

const AvivaLandingPage = (props) => {
    const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;

  const avivaVideo = useRef();
  const playButton = useRef();

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    document.querySelectorAll('.case-anchor').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
  
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
    });
  });

  const handlePlay = e => {
    if(!isPlaying) {
      setIsPlaying(true);
      avivaVideo.current.play();
    } else {
      setIsPlaying(false);
      avivaVideo.current.pause();
    }
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
              <div className='aviva--page-banner-image showreel'>
                <div className='video--wrapper'>
                  {isPlaying ? null :
                  <div className='video--overlay' onClick={handlePlay}>
                     <img src={PlayButton} ref={playButton} />
                  </div>
                  }
                  {content.bannerMedia.video && content.bannerMedia.video.asset && (
                    <video
                      ref={avivaVideo}
                      className="hero__media"
                      poster={imageUrlFor(buildImageObj(content.bannerMedia.image)).url()}
                      controls="false"
                    >
                      <source src={content.bannerMedia.video.asset.url} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

AvivaLandingPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default AvivaLandingPage;
